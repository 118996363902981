/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ErrorFactory, ErrorMap } from '@firebase/util';

export const enum AppCheckError {
  USE_BEFORE_ACTIVATION = 'use-before-activation'
}

const ERRORS: ErrorMap<AppCheckError> = {
  [AppCheckError.USE_BEFORE_ACTIVATION]:
    'App Check is being used before activate() is called for FirebaseApp {$appName}. ' +
    'Call activate() before instantiating other Firebase services.'
};

interface ErrorParams {
  [AppCheckError.USE_BEFORE_ACTIVATION]: { appName: string };
}

export const ERROR_FACTORY = new ErrorFactory<AppCheckError, ErrorParams>(
  'appCheck',
  'AppCheck',
  ERRORS
);
